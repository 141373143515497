import React from 'react';

import edit_button from '../images/edit_button.png';

import './StreamerBio.css';

export class StreamerBio extends React.Component {
    
    handleClick() {
      console.log(this.props)
      this.props.editProfile();
    }
    

    render() {
      return (
        <div className="streamer-bio-container">
          <div className="streamer-bio-header">
            <div className="streamer-name">
              {"Julian Moraes"}
            </div>
            <img 
              src={edit_button} 
              className='streamer-bio-edit-button' 
              alt="Streamer's profile pic"
              onClick={this.handleClick.bind(this)}
            />
          </div>
          

          <div className="streamer-occupation">
            {"Yoga Instructor"}
          </div>

          <div className="streamer-description">
            {"I believe in building strong a I believe in building strong a I believe in building strong a I believe in building strong and healthy bodies and minds! I pride myself in giving my clients my full dedication to help them in their fitness journey."}
          </div>

          <div className="streamer-link">
            {"samanthajohnson.com"}
          </div>
        </div>
      );
    }
}

