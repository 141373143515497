import React from 'react';
import Navigation from "react-sticky-nav";
import { Redirect } from 'react-router-dom';

import { NavBar } from '../components/NavBar.js';
import sign_up_with_zoom  from '../images/sign_up_with_zoom.png';
import avatar from '../images/missing-profile-pic-avatar.png';

import './LandingPage.css';

export class LandingPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
        events: [],
        user: {
          name: '',
          occupation: '',
          description: '',
          link: '',
        },
        isAddingEvent: false,
        isBookingEvent: false,
        isEditingProfile: true,
        selectedEvent: null,
        redirect: null,
    };
  }


  signUpWithZoom() {
    
  }
  
  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return (
      <div className="landing-page-container">
        <Navigation>
          <NavBar/>
        </Navigation> 
        <div>
          <img 
            src={sign_up_with_zoom} 
            onClick={this.signUpWithZoom.bind(this)}
            className='sign-up-button' 
            alt="Sign up button"
          />
        </div>
      </div>
    );
  }
}

