import React from 'react';
import './StreamerInfoContainer.css';
import { StreamerProfileImage } from './StreamerProfileImage';
import { StreamerBio } from './StreamerBio';

export class StreamerInfoContainer extends React.Component {
    
  render() {
    return (
        <div className="streamer-info-container">
          <StreamerProfileImage />
          <StreamerBio editProfile={() => this.props.editProfile()}/>
        </div>
    );
    }
}

