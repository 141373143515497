import * as firebase from 'firebase';

const db_setting = 'sandbox' //toggle to prod

// Initialize Firebase
const config = Object.freeze({
    apiKey: "AIzaSyAHG5FEv1m7sq-CcNBfp0H1ZeO98N0rhsc",
    authDomain: "livestream-website-2033a.firebaseapp.com",
    databaseURL: "https://livestream-website-2033a.firebaseio.com",
    projectId: "livestream-website-2033a",
    storageBucket: "livestream-website-2033a.appspot.com",
    messagingSenderId: "580730046133",
    appId: "1:580730046133:web:1b495838c2c50aee6de0fc",
    measurementId: "G-ES32CEBS72"
});

firebase.initializeApp(config);
firebase.analytics();

export function getCurrentTimestamp() {
    var date = new Date();
    return date.getTime().toString();
}

export function getFirebaseDB() {
    return firebase.database().ref(db_setting);
}
  
export async function createUser(first_name, last_name, email, password, auth_method) {
    //later make sure User doesn't already exist / email isn't already registered
    
    var user = {
        'name' : first_name + " " + last_name,
        'password' : password,
        'auth_method' : auth_method,
    };

    email = email.replace(".", "|||");

    getFirebaseDB().child('users/' + email).set(user);   
}

export async function createEvent(name, description, duration, host, price, max_num_participants) {
    const db = getFirebaseDB();

    var eventPayload = {
        'name' : name,
        'description' : description,
        'start_date' : getCurrentTimestamp(),
        'duration' : duration,
        'host' : host, 
        'price' : price,
        'max_num_participants' : max_num_participants,
    }; 

    var eventRef = db.child("events").push(eventPayload);

    var eventID = eventRef.key;
  
    //assign event record to user in Firebase
    db.child('users/' + host + "/events/" + eventID).set(true);
}

export async function getStreamerUpcomingEvents(userId) {
    const db = getFirebaseDB();

    userId = userId.replace(".", "|||");
    let events = await db.child("users/" + userId + "/events").once('value');
    return events;
}

export async function getEventDetails(eventId) {
    
    let event = await getFirebaseDB().child("events/" + eventId).once('value');
    return event;
}