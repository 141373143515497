import React from 'react';
import { Text } from 'react';
import { EventBubble } from './EventBubble.js';

import './UpcomingEventsList.css';

export class UpcomingEventsList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
       
    };
  }
  
  enrollInEvent(event) {
    if (!this.props.isStreamerViewingOwnProfile) {
      this.props.onEventSelected(event);
    }
  }

  editEvent(event) {
    console.log("EDIT EVENT")
    if (this.props.isStreamerViewingOwnProfile) {
      this.props.onEditEventSelected(event);
    }
  }

  getEvents() {
    var noUpcomingEvents = this.props.events === [];

    if (noUpcomingEvents) {
      return(
        <div className='no-upcoming-livestreams-text'>
          {"You have no upcoming livestreams."}
        </div>
      );
    }

    return(
      this.props.events.map(event => {

        return( 
          <EventBubble 
            key={event["key"]} 
            event={event} 
            onClick={() => this.enrollInEvent(event)}
            onEditEvent={() => this.editEvent(event)}
            isStreamerViewingOwnProfile={this.props.isStreamerViewingOwnProfile}
          />
        );
      })
    );
  }

  render() {
    const num_rows = Math.ceil(this.props.events.length / 4);
    var height = num_rows * 22;

    return (
      <div className="events-list-container">
        <div>
          <div className="events-list-title" >
            {"Upcoming Events"}
          </div>

          <div className="events-list-grid" style={{ height : height.toString() + "vh" }}>
            {this.getEvents()}
          </div>
        </div>
      </div>
    );
  }
}

