import React from 'react';
import { getDateStringFromTimeStamp } from '../../api/helperFunctions';
import edit_button from '../../images/edit_button.png';

import './EventBubble.css';
import { nominalTypeHack } from 'prop-types';

export class EventBubble extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isHovering : false, 
    };
  }

  handleEditEvent(){

  }

  render() {
    var timestamp = this.props.event['start_date'];
    var dateString = getDateStringFromTimeStamp(timestamp);

    return (
        <div 
          className="event-bubble" 
          onClick={this.props.onClick} 
          onMouseEnter={() => {this.setState({isHovering: true })}}
          onMouseLeave={() => {this.setState({isHovering: false })}}
          style={{cursor: this.props.isStreamerViewingOwnProfile ? 'default' : 'pointer'}}
        >
          <div className="event-name">
            {this.props.event['name']}
          </div>
          
          <div className="event-date"
            style={{marginBottom: this.props.isStreamerViewingOwnProfile ? "2.914vh" : "4.414vh"}}>
            {dateString['date'] + " at " + dateString["time"]} 
          </div>
          
          {this.props.isStreamerViewingOwnProfile ?
            <img 
              src={edit_button} 
              className='event-bubble-edit-button ' 
              alt="Streamer's profile pic"
              onClick={this.props.onEditEvent}
            />
            :
            <button className="enroll-button" style={{backgroundColor: this.state.isHovering ? "#12908E" : "#F0F7F6"}}>
              <text style={{color: this.state.isHovering ? "white" : "#12908E" }}>Enroll</text>
            </button>
          }
          
        </div>
    );
  }
}

