import React from 'react';
import Navigation from "react-sticky-nav";
import { Redirect } from 'react-router-dom';

import { NavBar } from '../components/NavBar.js';

import checkmark from '../images/checkmark.png';

import './PurchaseCompletePage.css';

export class PurchaseCompletePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      events: [],
      user: {
        name: '',
        occupation: '',
        description: '',
        link: '',
      },
      showEventPopUp: false,
      selectedEvent: null,
      redirect: null,
    };
  }

  getOrderDetails() {
    return(
      <div className="order-details-box">

        <div className="order-details-container">
          <text className="order-details-header-text"> Contact Information </text>
          <text className="order-details-standard-text" style={{marginTop: '1.35vh'}}>
            jessicathompson01@gmail.com
          </text>
          <text className="order-details-standard-text" style={{marginTop: '1.35vh'}}>
            (333) 500 - 6000
          </text>
          <text className="order-details-header-text" style={{marginTop: '8vh'}}>
            Payment Method
          </text>
          <text className="order-details-standard-text" style={{marginTop: '1.35vh'}}>
            Card ending in 1234
          </text>
        </div>

        <div className="order-details-container">
          <text className="order-details-header-text">Order Summary</text>

          <div className="order-details-cost-line">
            <text className="order-details-standard-text" style={{width: '24.93vw'}}>Foundational Yoga</text>
            <text className="order-details-standard-text" style={{width: '3.25vw', textAlign: "right"}}>$10000</text>
          </div>

          <div className="order-details-cost-line">
            <text className="order-details-standard-text" style={{width: '24.93vw'}}>Tax</text>
            <text className="order-details-standard-text" style={{width: '3.25vw', textAlign: "right"}}>$1</text>
          </div>

          <div className="purchase-complete-divider"> </div>
          
          <div className="order-details-cost-line">
            <text className="order-details-total-cost-text" style={{ width: '23.42vw' }}>Total</text>
            <text className="order-details-total-cost-price" style={{ width: '4.72vw' }}>$130000</text>
          </div>
        </div>
      </div>

    );
  }
  
  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return (
      <div className="purchase-complete-container">
        <Navigation>
          <NavBar/>
        </Navigation> 

        <img 
          src={checkmark} 
          className='purchase-complete-checkmark' 
          alt="Checkmark"
        />

        <text className="thank-you-text">Thank you for your purchase</text>
        <text className="order-confirmation-text">You'll receive an order confirmation in your inbox shortly</text>
        <text className="order-details-title-text">Order Details</text>
        {this.getOrderDetails()}
      </div>
    );
  }
}

