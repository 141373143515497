import React from 'react';
import Navigation from "react-sticky-nav";
import { Redirect } from 'react-router-dom';

import { NavBar } from '../components/NavBar.js';
import { StreamerInfoContainer } from '../components/StreamerInfoContainer';
import { UpcomingEventsList } from '../components/EventListContainer/UpcomingEventsList.js';
import { AddEventPopUp } from '../components/AddEventPopUp.js';
import { BookEventPopUp } from '../components/BookEventPopUp.js';
import { EditProfilePopUp } from '../components/EditProfilePopUp.js';

import { getStreamerUpcomingEvents, getEventDetails } from '../api/firebaseManager';

import './StreamerPage.css';

export class StreamerPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
        events: [],
        user: {
          name: '',
          occupation: '',
          description: '',
          link: '',
        },
        isAddingEvent: false,
        isBookingEvent: false,
        isEditingProfile: false,
        isStreamerViewingOwnProfile: false,
        selectedEvent: null,
        redirect: null,
    };
  }

  async componentDidMount() {
    getStreamerUpcomingEvents('jam869@cornell|||edu').then(response => {

      if (response !== null) {

        Object.keys(response.val()).forEach(eventId => {

          getEventDetails(eventId).then(event_response => {
            
            let key = event_response.key;
            let event_details = event_response.val();

            if (event_details !== null) {
              event_details['key'] = key;

              this.setState({ 
                events : this.state.events.concat(event_details)
              });
            }
          })
        })
      }
    });
  }

  toggleAddEventPopUp(event) {
    console.log("TOGGLING EDIT EVENT")
    console.log(event)
    console.log(event === null)

    this.setState({
      isAddingEvent : !this.state.isAddingEvent,
      selectedEvent : event,
    });
  }

  toggleBookEventPopUp(selected_event) {
    this.setState({
      selectedEvent: selected_event,
      isBookingEvent: !this.state.isBookingEvent,
    });
  }

  toggleEditProfilePopUp() {
    console.log("TOGGLING EDIT PROFILE")
    this.setState({
      isEditingProfile: !this.state.isEditingProfile,
    });
  }

  onCheckoutButtonPressed() {
    this.setState({ redirect : "/checkout" });
  }
  
  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    return (
      <div className="container">
        <Navigation>
          <NavBar/>
        </Navigation> 
        <StreamerInfoContainer editProfile={this.toggleEditProfilePopUp.bind(this)}/>
        <UpcomingEventsList 
          events={this.state.events} 
          onEventSelected={this.state.isStreamerViewingOwnProfile ? null : this.toggleBookEventPopUp.bind(this)}
          onEditEventSelected={this.toggleAddEventPopUp.bind(this)}
          isStreamerViewingOwnProfile={this.state.isStreamerViewingOwnProfile}
        />
        {this.state.isBookingEvent ? 
          <BookEventPopUp
            event={this.state.selectedEvent}
            onCheckoutButtonPressed={this.onCheckoutButtonPressed.bind(this)}
            closePopUp={this.toggleBookEventPopUp.bind(this)}
          />
          : null
        }

        {this.state.isAddingEvent ? 
          <AddEventPopUp 
            event={this.state.selectedEvent} 
            closePopUp={this.toggleAddEventPopUp.bind(this)}
          />
          : null
        }

        {this.state.isEditingProfile ? 
          <EditProfilePopUp
            closePopUp={this.toggleEditProfilePopUp.bind(this)}
          />
          : null
        }
      </div>
    );
  }
}

