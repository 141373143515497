import React from 'react';
import './NavBar.css';

export class NavBar extends React.Component {
    
    handleClick() {
      alert("HELLO");
    }

    render() {
      return (
        <div className="navbar-container">
          <button className="home-button" onClick={this.handleClick}>{"CLIP"}</button>
          <button className="explore-button">{"Explore"}</button>
          <button className='pricing-button'>{"Pricing"}</button>
          <button className='teach-button'>{"Teach"}</button>
          <button className = 'log-in-button'>{"Log In"}</button>
        </div>
      );
    }
}

