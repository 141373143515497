import React from 'react';
import exit_button from '../images/exit_button.png';

import { getDateStringFromTimeStamp } from '../api/helperFunctions';

import './EditProfilePopUp.css';

import avatar from '../images/missing-profile-pic-avatar.png';
import upload_photo_button from '../images/upload_images_button.png';

export class EditProfilePopUp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      image_url: "",
      first_name: "",
      last_name: "",
      professional_title: "",
      bio: "",
      website_link: "",
      instagram_link: "",
      twitter_link: "",
      youtube_link: "",
    };
  }

  saveProfile() {
    console.log();
  }

  handleChangeInput(event) {
    const { name, value, maxLength } = event.target;
    const message = maxLength > -1 ? value.slice(0, maxLength) : value;                 
    
    this.setState({
      [name] : message, 
    });
  }

  getProfileImage() {
    // var isProfileImageMissing = false; 

    // if (isProfileImageMissing) {
    //   return <img src={avatar} className='missing-pic-avatar' alt="Streamer's profile pic"/>;
    // } 

    if (this.state.image_url) {
      return <img src={this.state.image_url} className='streamer-profile-image' alt="No profile pic found avatar"/>
    }

    return <img src={avatar} className='edit-missing-pic-avatar' alt="Streamer's profile pic"/>;
  }


  getBioFrom() {
    return (
      <form className="edit-profile-popup-info-container" autoComplete="off">
        <div className="multi-input-row">
          <input 
            onChange={this.handleChangeInput.bind(this)}
            value={this.state.first_name}
            name="first_name"
            type="text" 
            placeholder="First Name" 
            maxLength="20"
            className="add-event-name-field" 
            style={{ width: '44.5%'}}
          />
          <div className="add-event-divider-vertical"/>
          <input
            onChange={this.handleChangeInput.bind(this)}
            value={this.state.last_name}
            name="last_name"
            type="text" 
            placeholder="Last Name" 
            maxLength="10"
            className="add-event-name-field" 
            style={{ width: '44.5%'}}
          />
        </div>
        <div className="add-event-divider-horizontal" />
        <input 
          onChange={this.handleChangeInput.bind(this)}
          value={this.state.professional_title}
          name="professional_title"
          type="text" 
          placeholder="Professional Title" 
          maxLength="50"
          className="add-event-name-field" 
        />
        <div className="add-event-divider-horizontal" />
        <textarea 
          onChange={this.handleChangeInput.bind(this)}
          value={this.state.bio}
          name="bio"
          type="text" 
          placeholder="Tell us a little bit about yourself..." 
          maxLength="825"
          className="add-event-description-field" 
          style={{ marginTop: '1.5%'}}
        />
        <div className="add-event-divider-horizontal" />

        <div className="multi-input-row">
          <input 
            onChange={this.handleChangeInput.bind(this)}
            value={this.state.website_link}
            name="website_link"
            type="text" 
            placeholder="Website Link" 
            maxLength="20"
            className="add-event-name-field" 
            style={{ width: '44.5%'}}
          />
          <div className="add-event-divider-vertical"/>
          <input 
            onChange={this.handleChangeInput.bind(this)}
            value={this.state.instagram_link}
            name="instagram_link"
            type="text" 
            placeholder="Instagram Link" 
            maxLength="10"
            className="add-event-name-field" 
            style={{ width: '44.5%'}}
          />
        </div>
        <div className="add-event-divider-horizontal" />

        <div className="multi-input-row">
          <input 
            onChange={this.handleChangeInput.bind(this)}
            value={this.state.twitter_link}
            name="twitter_link"
            type="text" 
            placeholder="Twitter Link" 
            maxLength="20"
            className="add-event-name-field" 
            style={{ width: '44.5%'}}
          />
          <div className="add-event-divider-vertical"/>
          <input 
            onChange={this.handleChangeInput.bind(this)}
            value={this.state.youtube_link}
            name="youtube_link"
            type="text" 
            placeholder="Youtube Link" 
            maxLength="10"
            className="add-event-name-field" 
            style={{ width: '44.5%'}}
          />
        </div>
      </form>
    );
  }

  handleChange = e => {
    if (e.target.files.length) {
      console.log("URL:")
      console.log(URL.createObjectURL(e.target.files[0]))

      this.setState({
        image_url: URL.createObjectURL(e.target.files[0])
       });

      // setImage({
      //   preview: URL.createObjectURL(e.target.files[0]),
      //   raw: e.target.files[0]
      // });
    }
  };
  
  render() {

    return (
      <div className="whole-screen-justify-content">
        <div className="event-popup-backdrop" onClick={this.props.closePopUp}/>
        <div className="edit-profile-popup-container">
          <div className="add-event-popup-header">
            <text className="edit-profile-title">Profile</text>
            <img  src={exit_button} className="add-event-pop-up-exit-button" 
              alt="Exit button" onClick={this.props.closePopUp}/>
          </div>
          <div className="edit-profile-image-container">
            {this.getProfileImage()}
          </div>
          

          <label htmlFor="upload-button" className="upload-button-container">
            <img src={upload_photo_button}  className='edit-profile-upload-photo-button' alt="Upload photo button"/>
          </label>
          <input
            type="file"
            id="upload-button"
            style={{ display: "none" }}
            onChange={this.handleChange}
          />
        
          
          {this.getBioFrom()}
          <div className="add-event-buttons-container">

            <div className="add-event-button" style={{
                color: 'white',
                backgroundColor: 'rgb(18,144,142)',
                cursor: 'pointer',
              }}
              onClick={this.saveProfile.bind(this)}
            > 
              {"Save"}
            </div>
          </div>
        </div>  
      </div>      
    );
  }
}