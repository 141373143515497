export function getTimeZone() {
  var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
  return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
}
export function getCurrentTimestamp() {
  var date = new Date();
  return date.getTime().toString();
}

function getDateStringFromDate() {

}

function getTimeStringFromDate(dt) {
  var hours = dt.getHours() ; // gives the value in 24 hours format
  
  var AmOrPm = hours >= 12 ? 'pm' : 'am';

  hours = (hours % 12) || 12;

  var minutes = dt.getMinutes();
  if (minutes < 10) { minutes = "0" + minutes.toString() } 
  
  var finalTime = hours + ":" + minutes + " " + AmOrPm; 

  return finalTime;
}

export function getDateStringFromTimeStamp(timestamp) {
  
  const date = new Date(Number(timestamp));
  
  var date_string = date.toDateString().split(" ", 3);
  date_string = date_string[0] + ", " + date_string[1] + " " + date_string[2];

  var time_string = getTimeStringFromDate(date).toUpperCase();
  
  // console.log("DATE:")
  // console.log(date_string);

  // console.log("TIME");
  // console.log(time_string)

  // TODO: Handle different timezones 
  //LINK: https://stackoverflow.com/questions/1091372/getting-the-clients-timezone-offset-in-javascript

  // console.log(Intl.DateTimeFormat().resolvedOptions().timeZone)

  // var offset = getTimeZone()//new Date().getTimezoneOffset();
  // console.log(offset);

  return({
    'date': date_string,
    'time':time_string,
  });
}