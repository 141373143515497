import React from 'react';
import './StreamerProfileImage.css';

import avatar from '../images/missing-profile-pic-avatar.png';
import headshot from '../images/julian_headshot.jpg';



export class StreamerProfileImage extends React.Component {
    
    getProfileImage() {
      var isProfileImageMissing = false; 

      if (isProfileImageMissing) {
        return <img src={avatar} className='missing-pic-avatar' alt="Streamer's profile pic"/>;
      } 

      return <img src={headshot} className='streamer-profile-image' alt="No profile pic found avatar"/>
    }

    render() {
      return (
        <div className="profile-image-container">
          {this.getProfileImage()}
        </div>
      );
    }
}

