import React from 'react';
import exit_button from '../images/exit_button.png';
import checkout_button from '../images/checkout_button.png';

import { getDateStringFromTimeStamp } from '../api/helperFunctions';

import './BookEventPopUp.css';

export class BookEventPopUp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      
    };
  }

  getEventInfoContainer() {
    var event_start_timestamp = Number(this.props.event["start_date"]); 
    var event_end_date = event_start_timestamp + Number(this.props.event["duration"]) * 60000;
    
    var start_date_string = getDateStringFromTimeStamp(event_start_timestamp);
    var end_date_string = getDateStringFromTimeStamp(event_end_date);

    var start_time = start_date_string["time"].split(" ")[0];
    var end_time = end_date_string["time"];

    return (
      <div className="event-popup-info-container">
        <div className="event-title-plus-price">
          <text className="event-popup-title-text">{this.props.event["name"]}</text>
          <text className="event-popup-price">${this.props.event["price"]}</text>
        </div>
        <div className="event-description">{this.props.event["description"]}</div>
        <div className="event-date-and-time"> 
          <text className="event-popup-date">{start_date_string["date"]}</text>
          <text className="event-popup-time">{start_time} - {end_time}</text>
        </div>
      </div>
    );
  }
  
  render() {

    return (
      <div className="whole-screen-justify-content">
        <div className="event-popup-backdrop" onClick={this.props.closePopUp}/>
        <div className="event-popup-container">
          <img  src={exit_button} className="pop-up-exit-button" 
            alt="Exit button" onClick={this.props.closePopUp}/>
          {this.getEventInfoContainer()}
          <div className="checkout-button-container">
            <text onClick={this.props.closePopUp} className="event-popup-cancel-text">Cancel</text>
            <img src={checkout_button} className="checkout-button" 
              alt="Checkout Button" onClick={this.props.onCheckoutButtonPressed}/>
          </div>
        </div>  
      </div>      
    );
  }
}