import React from 'react';
import Navigation from "react-sticky-nav";
import { Redirect } from 'react-router-dom';

import { NavBar } from '../components/NavBar.js';

import visa from '../images/visa_icon.png';
import mastercard from '../images/mastercard_icon.png';
import amex from '../images/amex_icon.png';
import discover from '../images/discover_icon.png';
import security_code from '../images/security_code.png';

import './CheckoutPage.css';

export class CheckoutPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      phone_number: "",
      customer_name: "",
      card_number: "",
      exp_date: "",
      security_code: "",
      zip_code: "",
      redirect: null,
    };
  }

  handleChangeInput(event) {
    const { name, value, maxLength } = event.target;
    const message = maxLength > -1 ? value.slice(0, maxLength) : value;                 
    
    this.setState({
      [name] : message, 
    });
  }

  isFormComplete() {
    const { email, phone_number, customer_name, card_number, exp_date, security_code, zip_code } = this.state;

    return (email !== "") && (phone_number !== "") && (customer_name !== "") && (card_number !== "") 
                            && (exp_date !== "") && (security_code !== "") && (zip_code !== "");    
  }

  completePurchase() {
    //run credit card info through Stripe

    //if Stripe approves payment
    // 1. log data in Firebase 
    // 2. Pay streamer and take your 10% commission
    // 3. record purchase in Firebase
    //  - add email to attendees list
    //  - add event to customer email record / create new record if customer's first time purchasing event
    // 4. add customer email to Zoom event 
    // 5. email customer with Zoom meeting details 
    // 6. let streamer know that they just made a sale
  
    console.log("COMPLETE PURCHASE")
  
    if (this.isFormComplete()) {
      console.log("FORM IS COMPLETE")
      this.setState({
        redirect: '/purchaseComplete',
      });
    }
  }

  getCustomerInfoModule() {
    return (
      <div className="customer-info-module"> 
        <text className="customer-info-title">Customer Info</text>
        <form className="customer-info-form" noValidate autoComplete="off">
          <input 
            onChange={this.handleChangeInput.bind(this)}
            value={this.state.email}
            name="email"
            type="text" 
            placeholder="Email" 
            maxLength="40"
            className="checkout-text-field" 
          />
          <div className="customer-info-module-divider-horizontal" />
          <input 
            onChange={this.handleChangeInput.bind(this)}
            value={this.state.phone_number}
            name="phone_number"
            type="text" 
            placeholder="Phone Number" 
            maxLength="12"
            className="checkout-text-field" 
          />
        </form>
      </div>
    );
  }

  getPaymentFormBottomRow() {
    return(
      <form className="payment-form-bottom-row" noValidate autoComplete="off">
        <input 
            onChange={this.handleChangeInput.bind(this)}
            value={this.state.exp_date}
            name="exp_date"
            type="text" 
            placeholder="Expiration Date" 
            className="checkout-text-field" 
            maxLength="20"
            style={{ width: '26.7742%'}}
        />
        <div className="customer-info-module-divider-vertical" />
        <input 
            onChange={this.handleChangeInput.bind(this)}
            value={this.state.security_code}
            name="security_code"
            type="text" 
            placeholder="Security code" 
            className="checkout-text-field" 
            maxLength="4"
            style={{  width: '25.5%', marginLeft: '0%' }}
        />
        {/* <img 
          src={security_code} 
          className='credit-card-icon' 
          alt="Security code icon"
          style={{ marginLeft: '10px', marginRight: '4.5%' }}
        /> */}
        <div className="customer-info-module-divider-vertical" />
        <input 
            onChange={this.handleChangeInput.bind(this)}
            value={this.state.zip_code}
            name="zip_code"
            type="text" 
            placeholder="Billing Zip Code" 
            className="checkout-text-field" 
            maxLength="10"
            style={{ width: '30%', marginLeft: '0%' }}
        />
      </form>
    );
  }

  getPaymentInfoModule() {
    return( 
      <div className="payment-info-module">
        <text className="customer-info-title">Payment Method</text>
        <form className="payment-info-form" noValidate autoComplete="off">
          <input 
            onChange={this.handleChangeInput.bind(this)}
            value={this.state.customer_name}
            name="customer_name"
            type="text" 
            placeholder="Name on card" 
            className="checkout-text-field" 
            maxLength="40"
          />
          <div className="customer-info-module-divider-horizontal" />
          <div className="credit-card-number-container">
            <input 
              onChange={this.handleChangeInput.bind(this)}
              value={this.state.card_number}
              name="card_number"
              type="text" 
              placeholder="Card Number" 
              className="checkout-text-field" 
              maxLength="19"
            />
            <div className="credit-card-icon-container">
              <img src={visa} className='credit-card-icon' alt="Visa icon"/>
              <img src={mastercard} className='credit-card-icon' alt="Mastercard icon"/>
              <img src={amex} className='credit-card-icon' alt="Amex icon"/>
              <img src={discover} className='credit-card-icon' alt="Discover card icon"/>
            </div>
          </div>
          <div className="customer-info-module-divider-horizontal" />
          {this.getPaymentFormBottomRow()}
        </form>
      </div> 
    );
  }

  getOrderSummary() {
    return(
      <div className="checkout-order-summary"> 
        <text className="order-summary-title">
            Order Summary
        </text>
        <div className="order-sum-cost-line" style={{ marginTop: '5.55%'}}>
          <text className="order-sum-cost-name">Foundational Yoga</text>
          <text className="order-sum-cost" style={{color: 'black'}}>$120</text>
        </div>
        <div className="order-sum-cost-line" style={{ marginTop: '4%'}}>
          <text className="order-sum-cost-name">Tax</text>
          <text className="order-sum-cost">$10</text>
        </div>
        <div className="order-sum-divider"/>
        <div className="order-sum-total-cost-line" style={{ marginTop: '4%'}}>
          <text className="order-sum-total-text">Total</text>
          <text className="order-sum-total-cost">$130</text>
        </div>
        <div className="order-sum-divider-bold"/> 
        
        <div className="order-sum-pay-button" style={{
            color: this.isFormComplete() ? 'white': 'rgb(152,163,161)',
            backgroundColor: this.isFormComplete() ? 'rgb(18,144,142)' : 'rgb(227,236,235)',
            cursor: this.isFormComplete() ? 'pointer' : 'auto',
          }}
          onClick={this.completePurchase.bind(this)}
        > 
          {"Pay"}
        </div>

      </div>
    );
  }
  
  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    
    return (
      <div className="checkout-page-container">
        <Navigation>
          <NavBar/>
        </Navigation> 
        <text className="checkout-title">Checkout</text>     
        <div className="checkout-info-container">
          <div className="customer-info-text-fields"> 
            {this.getCustomerInfoModule()}
            {this.getPaymentInfoModule()}
          </div>
          {this.getOrderSummary()}
        </div>   
      </div>
    );
  }
}