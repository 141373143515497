import React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import DayPicker from 'react-day-picker';
import exit_button from '../images/exit_button.png';

import { getDateStringFromTimeStamp } from '../api/helperFunctions';

import 'react-day-picker/lib/style.css';


import './AddEventPopUp.css';

export class AddEventPopUp extends React.Component {
  constructor(props) {
    super(props)
    this.childRef = React.createRef();

    this.state = {
      event_name: "",
      event_description: "",
      selected_date: "",
      selected_time: "",
      hour_selected: "",
      minute_selected: "",
      duration: null,
      num_participants: null,
      is_selecting_date: false,
      is_selecting_time: false,
      is_AM_selected: true,
      isFormComplete: false,
    };
  }

  addEvent() {
    console.log("");
  }

  selectDatePressed() {
    this.setState({
      is_selecting_date : true,
    });
  }

  componentDidMount() {
    if (this.props.event !== null) {

      var event_start_timestamp = Number(this.props.event["start_date"]); 
      
      var start_date_string = getDateStringFromTimeStamp(event_start_timestamp);

      var start_time = start_date_string["time"].split(" ")[0];
      var is_AM_selected = start_date_string["time"].split(" ")[1] === "AM" ? true : false; 


      var time_arr = start_time.split(":")

      var hours = time_arr[0];
      var minutes = time_arr[1];

      console.log(start_date_string)
      console.log(start_time)

      this.setState({
        event_name: this.props.event.name,
        event_description: this.props.event.description,
        duration: this.props.event.duration,
        num_participants: this.props.event.max_num_participants,
        is_AM_selected: is_AM_selected,
        selected_date: start_date_string["date"],
        selected_time: start_date_string["time"],
        hour_selected: hours,
        minute_selected: minutes,
      })

    }

    console.log("SELECTED EVENT")
    console.log(this.props.event)
  }

  handleChangeInput(event) {
    const { name, value, maxLength } = event.target;
    const message = maxLength > -1 ? value.slice(0, maxLength) : value;                 

    if (name === 'hour_selected' || name === 'minute_selected') {
      console.log("NAME")
      console.log(name)
      console.log(value);
    }

    this.setState({
      [name] : message, 
    });
  }

  isFormComplete() {
    const { event_name, event_description, selected_date, selected_time, duration, num_participants } = this.state;

    let result = ((event_name !== "") && (event_description !== "") && (selected_date !== "") && (selected_time !== "") 
                            && (duration > 0) && (num_participants > 0));    
    
    return result; 
  }

  handleDayClick(day, { selected } ) {
    const date_arr = day.toString().split(" ", 3);
    const date_string = date_arr[0] + ", " + date_arr[1] + " " + date_arr[2];

    console.log("handle")
    this.setState({
      selected_date: date_string,
      is_selecting_date: false,
    });
  }

  onOutsideClick() {
    if (this.state.is_selecting_date) {
      this.setState({
        is_selecting_date: false,
      });
    }

    if (this.state.is_selecting_time) {
      var selected_time = "";

      if (this.state.hour_selected !== "" || this.state.minute_selected !== "") {

        selected_time = Number(this.state.hour_selected).toString() + ":" + this.state.minute_selected;        
        selected_time = selected_time + (this.state.is_AM_selected ? " AM" : " PM");
      }

      this.setState({
        is_selecting_time: false,
        selected_time: selected_time,
      });
    }
  }

  closePopUp() {
    
    if (this.state.is_selecting_date || this.state.is_selecting_time) {
      this.setState({
        is_selecting_date: false,
        is_selecting_time: false,
      });
    } else {
      this.props.closePopUp();
    }
  }

  handleAMSelected() {
    if (this.state.is_AM_selected === false) {
      this.setState({
        is_AM_selected: true,
      });
    } 
  }

  handlePMSelected() {
    if (this.state.is_AM_selected === true) {
      this.setState({
        is_AM_selected: false,
      });
    } 
  }

  getTimeSelector() {
    if (this.state.is_selecting_time) {  
      return(
        <div className="add-event-time-picker">
          <form 
            autoComplete="off"
            className="time-picker-form"
            style={{ marginLeft: '4.75%', marginRight: '10%' }}
          > 
            <input 
              onChange={this.handleChangeInput.bind(this)}
              value={this.state.hour_selected}
              name="hour_selected"
              type="text" 
              placeholder="00" 
              maxLength="2"
              className="time-picker-input-container"
              style={{ marginRight: '10%'}}
            />

            <text className="time-picker-green-text">:</text>

            <input 
              onChange={this.handleChangeInput.bind(this)}
              value={this.state.minute_selected}
              name="minute_selected"
              type="text" 
              placeholder="00" 
              maxLength="2"
              className="time-picker-input-container"
              style={{ marginLeft: '10%'}}
            />

          </form>

          <text 
            className={this.state.is_AM_selected ?
              "time-picker-green-text"
                :
              "time-picker-gray-text"
            }
            onClick={this.handleAMSelected.bind(this)}
            style={{ cursor: 'pointer'}}
          >
            AM
          </text>

          <text 
            className={this.state.is_AM_selected ?
              "time-picker-gray-text"
                :
              "time-picker-green-text"
            }   
            style={{ marginLeft: '4%', cursor: 'pointer' }}
            onClick={this.handlePMSelected.bind(this)}
          >
            PM
          </text>
        </div>
      );
    }
  }

  getNewEventForm() {
    return (
      <form className="add-event-popup-info-container" autoComplete="off">
        <input 
          onChange={this.handleChangeInput.bind(this)}
          value={this.state.event_name}
          name="event_name"
          type="text" 
          placeholder="Event Name" 
          maxLength="50"
          className="add-event-name-field" 
        />
        <div className="add-event-divider-horizontal" />
        <textarea 
          onChange={this.handleChangeInput.bind(this)}
          value={this.state.event_description}
          name="event_description"
          type="text" 
          placeholder="Description" 
          maxLength="825"
          className="add-event-description-field" 
        />
        <div className="add-event-divider-horizontal" />
        <div className="multi-input-row">
          <input 
            onClick={() => {this.setState({
              is_selecting_date : true,
            })}}
            onChange={this.handleChangeInput.bind(this)}
            value={this.state.selected_date}
            name="selected_date"
            type="text" 
            placeholder="Select date" 
            maxLength="20"
            className="add-event-name-field" 
            style={{ cursor: 'pointer', marginLeft: '0%', width: '45.2%'}}
          />
          <div className="add-event-divider-vertical"/>
          <input
            onClick={() => {this.setState({
              is_selecting_time : true,
            })}}
            onChange={this.handleChangeInput.bind(this)}
            value={this.state.selected_time}
            name="selected_time"
            type="text" 
            placeholder="Start time" 
            maxLength="10"
            className="add-event-name-field" 
            style={{ cursor: 'pointer', marginRight: '0%', width: '45.3%'}}
          />
        </div>

        <OutsideClickHandler
          onOutsideClick={this.onOutsideClick.bind(this)}
        >
          {this.state.is_selecting_date ? 
            <DayPicker 
              className="date-picker"
              onDayClick={this.handleDayClick.bind(this)}
              domRef={this.childRef}
            /> 
              :
            null
          }
          {this.getTimeSelector()}
        </OutsideClickHandler>

        <div className="add-event-divider-horizontal" />
        <div className="multi-input-row">
          <div className="bottom-row-input-container" style={{marginRight: '2.23%', marginLeft: '2.23%'}}>
            <input 
              onChange={this.handleChangeInput.bind(this)}
              name="duration"
              value={this.state.duration}
              type="number" 
              placeholder="Duration" 
              maxLength="20"
              style={{  width: '91.6099%' }}
            />
            <text className="field-static-text">min</text>
           </div>
          <div className="add-event-divider-vertical" style={{ width: '0.2209vh' }}/>
          <div className="bottom-row-input-container" style={{marginRight: '2.23%', marginLeft: '2.23%'}}>
            <input 
              onChange={this.handleChangeInput.bind(this)}
              name="num_participants"
              value={this.state.num_participants}
              type="text" 
              placeholder="# of Participants" 
              maxLength="10"
              style={{ width: '75.2175%' }}
            />
            <text className="field-static-text">participants</text>
          </div>
        </div>
      </form>
    );
  }
  
  render() {
    return (
      <div className="whole-screen-justify-content">
        <div className="event-popup-backdrop" onClick={this.closePopUp.bind(this)}/>
        <div className="add-event-popup-container">
          <div className="add-event-popup-header">
            <text className="add-event-title">Event</text>
            <img  src={exit_button} className="add-event-pop-up-exit-button" 
              alt="Exit button" onClick={this.props.closePopUp}/>
          </div>

          {this.getNewEventForm()}
          <div className="add-event-buttons-container">
            <div className="add-event-button" style={{
                color: this.isFormComplete() ? 'white': 'rgb(152,163,161)',
                backgroundColor: this.isFormComplete() ? 'rgb(18,144,142)' : 'rgb(227,236,235)',
                cursor: this.isFormComplete() ? 'pointer' : 'auto',
              }}
              onClick={this.addEvent.bind(this)}
            > 
              {"Add Event"}
            </div>
          </div>
        </div>  
      </div>      
    );
  }
}