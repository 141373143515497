import React from 'react';
import { Route, Switch} from 'react-router-dom'

import { getFirebaseDB, createUser, createEvent } from './api/firebaseManager';
import { LandingPage } from './screens/LandingPage';
import { StreamerPage } from './screens/StreamerPage';
import { CheckoutPage } from './screens/CheckoutPage';
import { PurchaseCompletePage } from './screens/PurchaseCompletePage';

import { getCurrentTimestamp, getDateStringFromTimeStamp} from './api/helperFunctions';

import './App.css'; 

class App extends React.Component {
  
  handleClick() { 

    var firebaseDB = getFirebaseDB();
 
    //createUser('Julian', 'Moraes', 'jam869@cornell.edu', 'test_pw', 'email');

    createEvent('Foundational Yoga', 'This is a fun class', 40, 'jam869@cornell|||edu', 120, 99);
  }
  
  render() {
    console.log("PUBLIC URL")
    console.log(process.env.PUBLIC_URL)
    return (
        <Switch>
          <Route path='/' component={StreamerPage} exact />
          <Route path="/home"  component={LandingPage} />
          <Route path='/checkout' component={CheckoutPage} />
          <Route path='/purchaseComplete' component={PurchaseCompletePage} />
        </Switch>
    );
  }
}

export default App;
